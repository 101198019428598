import React from 'react'
import { Helmet } from 'react-helmet'

import Api from '../../svg/api.svg'
import Layout from '../../layout/layout'

import WatchVideo from '../../components/watch-video'
import ShoppingAreas from '../../components/shopping-areas'
import WatchVideoWithPlaceholder from '../../components/watch-video-with-placeholder'

import ExtendAssortment from '../../svg/extend-assortment.svg'
import Simple from '../../svg/simple.svg'
import Street from '../../svg/street.svg'


const Page = () => (

    <Layout>

        <Helmet>
          <title>Zupr - Maakt producten lokaal vindbaar</title>
          <meta name="description" content="Met Zupr laat je consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city street">
            <h1>Aanmelden bij Zupr</h1>
            <WatchVideo id="4S_tWVxrAzQ">
                Bekijk video
            </WatchVideo>
            <a className="btn btn-demo" href="https://www.zupr.io/nl/app">App downloaden</a>
            <div className="background"><Street /></div>
        </section>

        <section className="text-center divider">
            <div className="inner">
                <div className="text">
                    <p>Consumenten beginnen steeds vaker hun aankoopproces online. Daarom is het belangrijk dat jouw winkel en haar producten online goed zichtbaar zijn, ook als je ervoor kiest om je producten niet online te verkopen. Daar willen we je vanuit Zupr zo goed mogelijk bij helpen! Aanmelden bij Zupr is makkelijk. Download de app, maak een account aan en start met het toevoegen van je producten. Benieuwd hoe jouw winkel online getoond wordt? Neem dan een kijkje op www.zupr.nl!</p>
                </div>
            </div>
        </section>

        <section className="text-right divider">
            <div className="inner">
                <div className="text">
                    <h2>Direct online vindbaar</h2>
                    <p>Bij Zupr bieden we een online platform aan waarop je winkel en de producten die verkoopt direct online zichtbaar worden. Met een Zupr-account kan je:</p>
                    <ul className="numbered-list">
                      <li>Eenvoudig en snel je winkel aanmaken</li>
                      <li>Online verkopen zonder commissies</li>
                      <li>Eenvoudig producten toevoegen</li>
                      <li>Je eigen bezorgservice aanbieden</li>
                      <li>Samenwerken met je lokale mede-winkeliers</li>
                    </ul>
                </div>
                <div className="image">
                    <ExtendAssortment />
                </div>
            </div>
        </section>

        <section className="text-left">
            <div className="inner">
                <div className="text">
                    <h2>Waar wordt mijn winkel gevonden?</h2>
                    <p>Na het aanmaken van je account en het toevoegen van een gevelfoto, is je winkel online vindbaar op één of meerdere websites die door Zupr ondersteund worden. Zoek in de tabel hieronder op de naam van je gemeente of op de postcode van je winkel.</p>
                    <p>Bekijk tevens de <a href="/nl/quickstart">quickstart pagina</a> over hoe je het makkelijkst je assortiment, voorraad en prijs kunt koppelen.</p>
                </div>
                <div className="image">
                    <Simple />
                </div>
            </div>
        </section>

        <section className="text-center grey">
            <div className="inner">
                <div className="text">
                    <h2>Voorbeeld</h2>
                    <p>Benieuwd hoe een lokaal online platform van Zupr eruit kan zien? Winkeliers in Groningen maken al sinds 2019 gebruik van Zupr middels Warenhuis Groningen.</p>
                    <a target="_blank" rel="noopener noreferrer" className="btn btn-demo" href="https://www.warenhuisgroningen.nl/">Bekijk Warenhuis Groningen</a>
                </div>
            </div>
        </section>

        <section className="text-center divider">
            <div className="inner">
                <div className="text">
                    <h2>Kosten</h2>
                    <p>Zupr rekent geen commissies over bestellingen! Alleen de transactiekosten (€0,29 + 0,25%) worden aan je doorberekend. Dit maakt Zupr het meeste voordelige platform voor jou en je klanten. Wij zijn graag duidelijk over onze kosten, daarom leest je <a href="https://support.zupr.nl/hc/nl/articles/360013291580-Hoe-maak-ik-online-betalingen-mogelijk-">hier</a> hoe de transactiekosten zijn opgebouwd.</p>
                </div>
            </div>
        </section>

        <section className="text-left">
            <div className="inner">
                <div className="text">
                    <h2>Doe mee!</h2>
                    <p>Aanmelden is simpel. Binnen 10 minuten bent je online zichtbaar en kun je de eerste producten al aanbieden.</p>
                    <p>Deelname aan Zupr is enkel mogelijk als je beschikt over een fysieke locatie waar producten verkocht worden. Zupr is speciaal ontwikkeld voor de fysieke winkeliers en ondersteunt geen online winkels of diensten.</p>
                    <p>Bent je enthousiast en wil je nu gevonden worden op het lokale online platform? Zo kun je aanmelden:</p>
                    <ul className="numbered-list">
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.zupr.io/nl/app">Download</a> de gratis ZuprCharger app*</li>
                        <li>Maak een nieuw account aan in de app</li>
                        <li>Activeer je account via je mail</li>
                    </ul>
                     <small>* Beschikbaar voor zowel Android als iOS</small>
                </div>
                <div className="image">
                    <WatchVideoWithPlaceholder id="qJwH8k1eolc" image="meet-with-zupr.png">
                    </WatchVideoWithPlaceholder>
                </div>
            </div>
        </section>

        <section className="text-center grey">
            <div className="inner">
                <div className="text">
                    <h3>Heeft je al een account?</h3>
                    <p>Zodra je account geactiveerd is, kun je beginnen met het toevoegen van producten. In onze <a href="https://support.zupr.nl/">kennisbank</a> lees je hier meer over. Wil je ook een eigen bezorgservice aanbieden? Dat kan! Met ons platform kunt je eenvoudig betalingen afhandelen en bezorgen faciliteren. Lees <a href="https://support.zupr.nl/hc/nl/articles/360017325840-Hoe-stel-ik-de-betaal-en-afhaalmogelijkheden-van-mijn-winkel-in-">hier</a> hoe je deze instelt en hoe bestellingen afgewerkt worden.</p>
                    <div className="widget-statistics">
                        <Api />
                        <p>Voeg na het aanmaken van je winkel ook direct een afbeelding van de winkel toe. Pas na het toevoegen van een afbeelding van de winkelgevel, ben je zichtbaar op het platform van Zupr. Lees <a href="https://support.zupr.nl/hc/nl/articles/360012448580-Hoe-beheer-ik-de-afbeeldingen-van-mijn-winkel-">hier</a> hoe je een gevelfoto toevoegt.</p>
                    </div>
                </div>
            </div>
        </section>
        <a name="areas"></a>

        <section className="text-center zupr-shopping-areas divider">
            <div className="inner">
                <div className="text">
                    <h2>Shopping areas van Zupr</h2>
                    <ShoppingAreas />
                </div>
            </div>
        </section>

        <section className="contact-boost">
            <div className="inner">
                <h2>Heeft je vragen?</h2>
                <p>Heeft je vragen over ons aanbod of wilt je eerst meer weten over Zupr? Bel ons of stuur een e-mail naar <a href="mailto:support@zupr.nl?subject=Interesse in Zupr">support@zupr.nl</a> of bezoek onze kennisbank op <a href="https://support.zupr.nl/">https://support.zupr.nl</a>.</p>
                <p>
                    <a href="mailto:support@zupr.nl?subject=Interesse in Zupr" className="email">support@zupr.nl</a>
                    <a href="tel:+31854011300" className="telephone">+31 (0)85 40 11 306</a>
                </p>
            </div>
        </section>

    </Layout>
)

export default Page
